import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private backendUrl = environment.backendEndpoint;
  private userDetailsCache: any | null = null; // Cache for user details

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  /**
   * Check if the user is logged in by checking the presence of an auth token.
   */
  isLoggedIn(): boolean {
    const token = localStorage.getItem('auth_token');
    return !!token; // True if token exists
  }

  /**
   * Register a new user.
   * @param userData Object containing user registration details.
   */
  registerUser(userData: {
    email: string;
    name: string;
    userName?: string;
    userRoleId?: number;
    phoneNumber?: string;
  }): Observable<any> {
    return this.http.post(`${this.backendUrl}/auth/registerUser`, userData);
  }

  /**
   * Store the authentication token in localStorage.
   * @param token The token to store.
   */
  storeToken(token: string): void {
    localStorage.setItem('auth_token', token);
  }

  /**
   * Retrieve the authentication token from localStorage.
   */
  getToken(): string | null {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('auth_token');
    }
    return null;
  }

  /**
   * Delete the authentication token from localStorage and clear the user details cache.
   */
  deleteToken(): void {
    localStorage.removeItem('auth_token');
    this.clearUserDetailsCache(); // Clear cache when token is removed
  }

  /**
   * Get headers with the authorization token for authenticated requests.
   */
  getAuthHeaders(): { headers: HttpHeaders } {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return { headers };
  }

  /**
   * Fetch user details with caching.
   * If details are cached, return them immediately.
   * Otherwise, fetch from the backend and cache them.
   */
  getUserDetails(updateCache: boolean = false): Observable<any> {
    const token = this.getToken();

    // If there's no token, skip backend call and treat as unauthenticated
    if (!token) {
      return new Observable((observer) => {
        observer.error(new Error('No authentication token found'));
        observer.complete();
      });
    }

    // Bypass cache if updateCache is true
    if (!updateCache) {
      // Return cached user details if available
      if (this.userDetailsCache) {
        return new Observable((observer) => {
          observer.next(this.userDetailsCache);
          observer.complete();
        });
      }

      // Check localStorage for cached details
      if (isPlatformBrowser(this.platformId)) {
        const cachedDetails = localStorage.getItem('user_details');
        if (cachedDetails) {
          this.userDetailsCache = JSON.parse(cachedDetails);
          return new Observable((observer) => {
            observer.next(this.userDetailsCache);
            observer.complete();
          });
        }
      }
    }

    // Fetch details from the backend and update the cache
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return new Observable((observer) => {
      this.http
        .get(`${this.backendUrl}/auth/getUserDetails`, { headers })
        .subscribe({
          next: (response: any) => {
            this.userDetailsCache = response;
            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem('user_details', JSON.stringify(response));
            }

            observer.next(response);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          }
        });
    });
  }

  /**
   * Clear the cached user details from memory and localStorage.
   */
  private clearUserDetailsCache(): void {
    this.userDetailsCache = null;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('user_details');
    }
  }

  /**
   * Send a verification code to the user's email for login.
   * @param body Object containing the user's email.
   */
  sendVerificationCode(body: { email: string }): Observable<any> {
    return this.http.post(
      `${this.backendUrl}/email/loginVerificationCode`,
      body
    );
  }

  /**
   * Log in the user with email and code.
   * @param body Object containing the email and code.
   */
  login(body: { email: string; code: string }): Observable<any> {
    return this.http.post(`${this.backendUrl}/auth/login`, body);
  }

  /**
   * Check if the user has access to the dashboard.
   */
  isDashboardUser(): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(`${this.backendUrl}/auth/isDashboardUser`, {
      headers
    });
  }
}
